"use client";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { COLORS } from "../constants";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Almarai } from "next/font/google";
import { useEffect } from "react";
// import { useDirectionContext } from "@/context/direction";

const almarai = Almarai({
  subsets: ["arabic"],
  weight: ["300", "400", "700", "800"],
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});

const theme = createTheme({
  direction: "ltr",
  typography: {
    fontFamily: almarai.style.fontFamily,
  },
  palette: {
    primary: {
      main: COLORS.primary, //red
    },
    secondary: {
      main: COLORS.secondary, // black
    },
    third: COLORS.third,
    rateColor: COLORS.rateColor, //orange
    green: COLORS.green,
    white: COLORS.white,
    gray: COLORS.gray,
    primaryBackground: COLORS.background, // white
    secondaryBackground: COLORS.secondaryBackground, // Grey
  },
});

export default function RTL({
  children,
  lang,
}: {
  children: React.ReactNode;
  lang: string;
}) {
  // const { themeDirection } = useDirectionContext();

  // useEffect(() => {
  //   document.dir = themeDirection;
  // }, [themeDirection]);

  const isRTL = lang === "ar";

  return (
    <ThemeProvider theme={theme}>
      {isRTL ? (
        <CacheProvider value={cacheRtl}>{children}</CacheProvider>
      ) : (
        <>{children}</>
      )}
      {/* {children} */}
    </ThemeProvider>
  );
}
