"use client";

import React, { useEffect, useState } from "react";
import i18n from "@/locales/i18n";
import AppBar from "./appbar";
import HomeCategoriesTabs from "./home-categories-tabs";
import Sidebar from "./sidebar";
import AppFooter from "./app-footer";
// import LoadingSpinner from "./loading-spinner";
import RTL from "@/utils/rtl";
import { DirectionProvider } from "@/context/direction";
import { I18nextProvider } from "react-i18next";
import { usePathname } from "next/navigation";
import { Box, Container } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "@/context/auth";
import CartDrawer from "@/sections/cart/cart-drawer";
import { CartDrawerProvider } from "@/context/cart";
import { CompanyProvider } from "@/context/company";
import { Company } from "@/type/company";

export default function AppLayout({
  children,
  lang,
  company,
}: {
  children: React.ReactNode;
  lang: string;
  company: Company;
}) {
  const pathname = usePathname();

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const storedLang = localStorage.getItem("i18nextLng");
  //     if (storedLang) {
  //       i18n.changeLanguage(storedLang).then(() => setLoading(false));
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  // }, []);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const isLoginPage = pathname === "/login" || pathname === "/signup";

  // if (loading) {
  //   return <LoadingSpinner />;
  // }

  return (
    <>
      <AuthProvider>
        <DirectionProvider>
          <CompanyProvider company={company} currentLang={lang}>
            <CartDrawerProvider>
              <I18nextProvider i18n={i18n}>
                <RTL lang={lang}>
                  <Toaster position="top-center" />

                  {isLoginPage ? (
                    <Box>{children}</Box>
                  ) : (
                    <>
                      <Container>
                        <Box>
                          <Sidebar open={open} toggleDrawer={toggleDrawer} />
                          <AppBar
                            handleDrawerOpen={handleDrawerOpen}
                            company={company}
                            lang={lang}
                          />
                          <CartDrawer lang={lang} />

                          <HomeCategoriesTabs company={company} lang={lang} />

                          <Box sx={{ minHeight: "100vh" }}>
                            <Box>{children}</Box>
                          </Box>
                        </Box>
                      </Container>
                      <AppFooter company={company} lang={lang} />
                    </>
                  )}
                </RTL>
              </I18nextProvider>
            </CartDrawerProvider>
          </CompanyProvider>
        </DirectionProvider>
      </AuthProvider>
    </>
  );
}
