"use client";

import { useSidebarDrawer } from "@/context/sidebar";
import DehazeIcon from "@mui/icons-material/Dehaze";

export default function MobileSidebarButton() {
  const { toggleDrawer } = useSidebarDrawer();
  return (
    <DehazeIcon
      onClick={() => toggleDrawer(true)}
      sx={{ display: { xs: "block", lg: "none" } }}
    />
  );
}
