"use client";

import * as React from "react";
import Image from "next/legacy/image";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";

import { Typography } from "@mui/material";
// import { useTranslate } from "@/locales/config-lang";
import { useRouter } from "next/navigation";
import { useDirectionContext } from "@/context/direction";
// import { getValueFromLocalStorage } from "@/utils/localStorage";
import { useTranslation } from "@/app/i18n/client";
import { Company } from "@/type/company";
import { useSidebarDrawer } from "@/context/sidebar";

const appTabs = [
  "discounts",
  "products",
  "new-arrivals",
  "best-seller",
  "shipping-policy",
];

type SidebarProps = {
  lang: string;
  company: Company;
};

export default function Sidebar({ lang, company }: SidebarProps) {
  const { t } = useTranslation(lang, "translation", {});

  const { open, toggleDrawer } = useSidebarDrawer();
  // const { themeDirection } = useDirectionContext();

  // const company = getValueFromLocalStorage("company");

  const router = useRouter();

  const DrawerList = (
    <Box
      sx={{ width: 260, pl: "24px", pt: "24px" }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      {company?.logo ? (
        <Image
          src={company?.logo}
          alt=""
          width={22}
          height={22}
          sizes="(max-width: 768px) 18px, 22px"
        />
      ) : (
        <FlagCircleIcon />
      )}

      <Box sx={{ mb: "32px" }}>
        <Typography
          sx={{ mb: "24px", color: "rgba(0, 0, 0, 0.4)", fontSize: "14px" }}
        >
          {t("categories")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            color: "secondary.main",
          }}
        >
          {appTabs.map((tab) => (
            <Box key={tab}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "300" }}
                onClick={() => router.push("/")}
              >
                {t(tab)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={lang === "en" ? "left" : "right"}
        open={open}
        onClose={() => toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            right: "unset",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
