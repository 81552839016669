import * as React from "react";
import Image from "next/legacy/image";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import { useTranslate } from "@/locales/config-lang";
import { useRouter } from "next/navigation";
import { useDirectionContext } from "@/context/direction";

const categories = [
  { value: "televisions", url: "/", img: "/images/tv-vector.png" },
  { value: "phons", url: "/", img: "/images/care.png" },
  { value: "large-appliances", url: "/", img: "/images/vector2.png" },
  { value: "small-appliances", url: "/", img: "/images/vector.png" },
  { value: "computers", url: "/", img: "/images/lab.png" },
  { value: "personal-care", url: "/", img: "/images/care.png" },
];

type SidebarProps = {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
};

export default function Sidebar({ open, toggleDrawer }: SidebarProps) {
  const { t } = useTranslate();

  const { themeDirection } = useDirectionContext();

  const router = useRouter();

  const DrawerList = (
    <Box
      sx={{ width: 260, pl: "24px", pt: "40px" }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      <Image src={"/images/logo.png"} alt="" width={22} height={22} />

      <Typography
        sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.4)", my: "24px" }}
      >
        {t("personal-account")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "70%",
          mb: "32px",
          color: "secondary.main",
        }}
        onClick={() => router.push("/login")}
      >
        <Image
          src={"/images/profile.png"}
          alt=""
          width={16}
          height={16}
          color="rgba(0, 0, 0, 0.6)"
        />

        <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
          {t("login-register")}
        </Typography>
      </Box>

      <Box sx={{ mb: "32px" }}>
        <Typography
          sx={{ mb: "24px", color: "rgba(0, 0, 0, 0.4)", fontSize: "14px" }}
        >
          {t("main-links")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            color: "secondary.main",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <Image src={"/images/favorite.png"} alt="" width={14} height={14} />

            <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
              {t("favorites")}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <Image src={"/images/offer.png"} alt="" width={14} height={14} />
            <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
              {t("offers")}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <Image
              src={"/images/best-seller.png"}
              alt=""
              width={14}
              height={14}
            />
            <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
              {t("best-seller")}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <Image src={"/images/popular.png"} alt="" width={14} height={14} />
            <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
              {t("most-popular")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mb: "32px" }}>
        <Typography
          sx={{ mb: "24px", color: "rgba(0, 0, 0, 0.4)", fontSize: "14px" }}
        >
          {t("categories")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            color: "secondary.main",
          }}
        >
          {categories.map((category) => (
            <Box key={category.value}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
                <Image src={category.img} alt="" width={14} height={14} />

                <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
                  {t(`${category.value}`)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={themeDirection === "ltr" ? "left" : "right"}
        open={open}
        onClose={() => toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            right: 'unset' ,
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
