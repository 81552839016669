"use client";

import { Avatar, Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { MouseEvent, useCallback, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LoginIcon from "@mui/icons-material/Login";
import { useLocales, useTranslate } from "@/locales/config-lang";
import { useTranslation } from "react-i18next";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Image from "next/legacy/image";
import AppBarSearch from "./appbar-search";
import { useRouter } from "next/navigation";
import { useAuth } from "@/context/auth";
import { useCartDrawer } from "@/context/cart";
import { Company } from "@/type/company";

type AppBarProps = {
  handleDrawerOpen: () => void;
  company: Company;
  lang: string;
};

export default function AppBar({
  handleDrawerOpen,
  company,
  lang,
}: AppBarProps) {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { enableLogin } = company;

  // const { themeDirection } = useDirectionContext();

  const { openDrawer, cartItemsCount } = useCartDrawer();

  const router = useRouter();

  const { onChangeLang } = useTranslate();

  const { allLangs, currentLang } = useLocales();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = useCallback(
    (newLang: string) => {
      onChangeLang(newLang);
      handleClose();
    },
    [onChangeLang]
  );

  return (
    <Box
      sx={{
        pl: { xs: "16px", md: "26px" },
        pr: { xs: "22px", md: "40px" },
        py: { xs: "20px", md: "14px" },
        display: "grid",
        justifyContent: "space-between",
        alignItems: "center",
        gridTemplateColumns: { xs: "repeat(3, 1fr)", lg: "repeat(3, auto)" },
        gridTemplateRows: "auto",
        gridTemplateAreas: {
          lg: `"logo search icons" `,
          xs: `"logo logo icons" 
               "search search search"`,
        },
      }}
    >
      <Box
        sx={{
          width: "10%",
          gridArea: "logo",
          display: "flex",
          alignItems: "center",
          gap: { xs: "8px", lg: "17px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "18px", lg: "22px" },
            height: { xs: "18px", lg: "22px" },
            position: "relative",
          }}
        >
          <Image
            src={company?.logo || "/images/logo.png"}
            alt=""
            width={22}
            height={22}
            sizes="(max-width: 768px) 18px, 22px"
          />
        </Box>

        <Typography
          sx={{
            fontWeight: "800",
            fontSize: "22px",
            color: "primary.main",
          }}
        >
          {company?.name}
        </Typography>
      </Box>

      <AppBarSearch />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: { xs: "16px", lg: "20px" },
          color: "rgba(0, 0, 0, 0.6)",
          gridArea: "icons",
        }}
      >
        {!company?.settings?.hideLangSwitcher && (
          <Box
            sx={{
              display: { xs: "flex", lg: "flex" },
              width: { xs: "40px", lg: "60px" },
            }}
          >
            <Button
              id="basic-button"
              aria-controls={isOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : undefined}
              onClick={handleClick}
              sx={{ width: { xs: "40px", lg: "60px" } }}
            >
              {currentLang.value}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {allLangs.map((option) => (
                <MenuItem
                  key={option.value}
                  selected={option.value === currentLang.value}
                  onClick={() => handleChangeLang(option.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
        {company?.settings?.showFavoritesPage && <FavoriteBorderIcon />}

        <Box position={"relative"}>
          {!!cartItemsCount && (
            <Box
              sx={{
                position: "absolute",
                top: "-10px",
                right: lang === "en" ? "-10px" : "10px",
                minWidth: "20px",
                height: "20px",
                width: "max-content",
                borderRadius: "50%",
                color: "white",
                fontSize: "14px",
                backgroundColor: "primary.main",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cartItemsCount}
            </Box>
          )}

          <ShoppingCartIcon
            sx={{ cursor: "pointer" }}
            onClick={() => openDrawer(true)}
          />
        </Box>

        <DehazeIcon
          onClick={handleDrawerOpen}
          sx={{ display: { xs: "block", lg: "none" } }}
        />

        {user && (
          <Box sx={{ display: { xs: "none", lg: "flex" } }}>
            <Avatar
              src="/images/Ellipse 50.png"
              sx={{ width: "32px", height: "32px", cursor: "pointer" }}
              onClick={() => router.push("/account")}
            >
              {user?.user.name}
            </Avatar>
          </Box>
        )}

        {enableLogin && (
          <Box
            sx={{
              color: "primary.main",
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              gap: "2px",
              cursor: "pointer",
            }}
            onClick={() => router.push("/login")}
          >
            <LoginIcon />
            <Typography sx={{ fontWeight: "700", fontSize: "14px" }}>
              {t("login")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
