/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { useCartDrawer } from "@/context/cart";
import { Box, Button, Divider, Drawer, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
import CartDrawerCard from "./cart-drawer-card";

import { useRouter } from "next/navigation";
import { useTranslation } from "@/app/i18n/client";
import { useCompany } from "@/context/company";

export default function CartDrawer({ lang }: { lang: string }) {
  const router = useRouter();
  const { t } = useTranslation(lang, "translation", {});

  const { company } = useCompany();

  const { open, toggleDrawer, cartItems } = useCartDrawer();

  const total = cartItems.reduce((total, item) => {
    const price = item.product.isOnSale
      ? item.product.onSalePrice
      : item.product.regularPrice;
    return total + price * item.quantity;
  }, 0);

  const handleSubmitOrder = () => {
    router.push("/cart");
    toggleDrawer(false);
  };

  const anchorDir = lang === "en" ? "left" : "right";

  return (
    <Drawer
      anchor={anchorDir}
      open={open}
      onClose={() => toggleDrawer(!open)}
      PaperProps={{
        sx: {
          height: "100vh",
          overflowY: "hidden",
        },
      }}
      sx={{
        "& .MuiDrawer-paper": {
          right: "unset",
        },
      }}
    >
      <Box sx={{ py: "20px", width: { xs: "300px", lg: "400px" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "24px",
            px: "16px",
          }}
        >
          <Typography> {`${t("cart")} (${cartItems.length})`} </Typography>

          <CloseIcon
            onClick={() => toggleDrawer(!open)}
            sx={{ cursor: "pointer" }}
          />
        </Box>

        <Box
          className="scroll-container  drawer-scroll"
          sx={{
            minHeight: {
              xs: "calc(100vh - 300px)",
              lg: "calc(100vh - 270px)",
            },
            height: {
              xs: "calc(100vh - 300px)",
              lg: "calc(100vh - 270px)",
            },
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflowY: "auto",
            mr: "8px",
          }}
        >
          {cartItems.map((item) => (
            <CartDrawerCard key={item.id} item={item} />
          ))}
        </Box>

        <Divider sx={{ mt: "20px", mb: "20px", px: "16px" }} />

        <Box sx={{ textAlign: "center", px: "16px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "16px",
            }}
          >
            <Typography
              sx={{
                color: "#959595",
                fontSize: "16px",
                fontWeight: { xs: "300", lg: "400" },
              }}
            >
              {t("total")}
            </Typography>
            <Typography
              sx={{
                color: "secondary.main",
                fontSize: { xs: "16px", lg: "18px" },
                fontWeight: { xs: "400", lg: "700" },
              }}
            >
              {`${total.toLocaleString()} ${t(company?.currency || "USD")}`}
            </Typography>
          </Box>

          <Button
            sx={{
              p: "auto",
              height: "50px",
              width: "100%",
              backgroundColor:
                company?.activeTheme === "simple" ? "primary.main" : "#000",
              fontWeight: "700",
              color: "white",
              borderRadius: "2px",
              textTransform: "capitalize",
              mb: "16px",
              "&:hover": {
                backgroundColor:
                  company?.activeTheme === "simple" ? "primary.main" : "#000",
              },
            }}
            onClick={handleSubmitOrder}
          >
            {t("submit-order")}
          </Button>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "secondary.main",
                textDecoration: "underline",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                toggleDrawer(false);
              }}
            >
              {t("follow-shopping")}
            </Typography>
            {lang === "en" ? <EastIcon /> : <KeyboardBackspaceIcon />}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
