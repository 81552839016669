//@deprecated
// This file is deprecated and will be removed in the future.
//USE app/i18n instead

"use client";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from "./langs/en.json";
import translationAr from "./langs/ar.json";
import { defaultLang } from "./config-lang";

let lng = defaultLang.value; // Default to English

if (typeof window !== "undefined") {
  const storedLang = window.localStorage.getItem("i18nextLng");
  if (storedLang) {
    lng = storedLang;
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: defaultLang.value,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: translationEn },
      ar: { translation: translationAr },
    },
    lng,
  });

export default i18n;
