import { Box, Button, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslate } from "@/locales/config-lang";

export default function AppBarSearch() {
  const { t } = useTranslate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gridArea: "search",
        mt: { xs: "16px", lg: "0px" },
      }}
    >
      <TextField
        placeholder={t("search")}
        sx={{
          width: "586px",
          height: { xs: "40px", lg: "44px" },
          backgroundColor: {
            xs: "secondaryBackground",
            lg: "primaryBackground",
          },
          borderRadius: { xs: "20px", lg: "20px 0px 0px 20px" },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: {
              xs: "rgba(250, 250, 250, 1)",
              lg: "rgba(0, 0, 0, 0.4) !important",
            },
            borderRight: { lg: "none" },
            borderRadius: { xs: "20px", lg: "20px 0px 0px 20px" },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: { xs: "none", lg: "rgba(0, 0, 0, 0.4) !important" },
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: {
              xs: "0px",
              lg: "0.5px solid rgba(0, 0, 0, 0.4) !important",
            },
            borderRight: "none !important",
            borderRadius: { xs: "20px", lg: "20px 0px 0px 20px" },
          },
          "& .MuiInputBase-root ": {
            height: "inherit",
            pl: "16px",
            py: "11px",
          },
          "& .MuiInputBase-input": {
            height: "inherit",
            padding: "0px",
            "&::placeholder": {
              fontWeight: "300",
              fontSize: { xs: "14px", lg: "16px" },
              color: "rgba(0, 0, 0, 0.4)",
              opacity: "1",
            },
          },
        }}
      />

      <Button
        sx={{
          width: "64px",
          height: "44px",
          backgroundColor: "primary.main",
          display: { xs: "none", lg: "flex" },
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0px",
          borderTopRightRadius: "20px",
          borderBottomRightRadius: "20px",
          color: "white",
          "&:hover": {
            bgcolor: "primary.main",
            color: "white",
          },
        }}
      >
        <SearchIcon />
      </Button>
    </Box>
  );
}
