export const getValueFromLocalStorage = (key: string) => {
  if (typeof window === "undefined") return null;
  const storedValue = localStorage?.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

export const setValueToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeValueFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};