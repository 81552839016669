"use client";

import { createContext, useContext, useMemo, useState } from "react";

type SidebarDrawerContext = {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
};

const initialState: SidebarDrawerContext = {
  open: false,
  toggleDrawer: () => {},
};

export const SidebarDrawerContext =
  createContext<SidebarDrawerContext>(initialState);

export const SidebarDrawerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const memoizedValue = useMemo(
    () => ({
      open,
      toggleDrawer,
    }),
    [open]
  );

  return (
    <SidebarDrawerContext.Provider value={memoizedValue}>
      {children}
    </SidebarDrawerContext.Provider>
  );
};

export const useSidebarDrawer = () => {
  const context = useContext(SidebarDrawerContext);
  if (context === undefined) {
    throw new Error(
      "useSidebarDrawer must be used within a SidebarDrawerContext"
    );
  }
  return context;
};
