
export const COLORS = {
  primary: "#820000", // red
  secondary: "#1A1A1A", // black
  third: "#656565 ", // gray
  background: "#FFFFFF", // white background
  secondaryBackground: "#FAFAFA",
  thirdBackground: "#F8F8F8",
  discountButtonColor: "#FD724F",
  rateColor: "#F89236",
  green: "#11A104",
  white: "#fff",
  gray: "#959595",
};
