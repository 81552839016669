"use client";

import { Box } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useCartDrawer } from "@/context/cart";

export default function CartButton({ lang }: { lang: string }) {
  const { openDrawer, cartItemsCount } = useCartDrawer();
  return (
    <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
      <Box position={"relative"}>
        {!!cartItemsCount && (
          <Box
            sx={{
              position: "absolute",
              top: "-5px",
              right: lang === "en" ? "8px" : "-8px",
              minWidth: "20px",
              height: "20px",
              width: "max-content",
              borderRadius: "50%",
              color: "white",
              fontSize: "14px",
              backgroundColor: "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cartItemsCount}
          </Box>
        )}
        <ShoppingBagOutlinedIcon
          sx={{ cursor: "pointer" }}
          onClick={() => openDrawer(true)}
        />
      </Box>
    </Box>
  );
}
