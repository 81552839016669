"use client";

import { CartDeleteProps, CartItemsProps } from "@/type/cart";
import { ProductConfigsProps, ProductProps } from "@/type/product";
import {
  getValueFromLocalStorage,
  setValueToLocalStorage,
} from "@/utils/localStorage";
import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

type CartContextType = {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
  openDrawer: (open: boolean) => void;
  cartItems: CartItemsProps[];
  cartItemsCount: number;
  deleteCartItem: ({ cartItemId }: CartDeleteProps) => void;
  addProductToCart: (
    product: ProductProps | ProductConfigsProps | undefined,
    itemQuantity: number,
  ) => void;
  clearCartItems: () => void;
  handleItemQuantityChange: (itemId: string, quantity: number) => void;
  setDefaultQuantityOnBlur: (itemId: string) => void;
};

const initialState = {
  open: false,
  toggleDrawer: () => {},
  openDrawer: () => {},
  cartItems: [],
  cartItemsCount: 0,
  deleteCartItem: () => {},
  addProductToCart: () => {},
  clearCartItems: () => {},
  handleItemQuantityChange: () => {},
  setDefaultQuantityOnBlur: () => {},
};

const CartDrawerContext = createContext<CartContextType>(initialState);

export const CartDrawerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartItemsCount, setCartItemsCount] = useState(0);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const openDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const cartItems = getValueFromLocalStorage("cartItems");
    if (cartItems) {
      setCartItems(cartItems);
      setCartItemsCount(cartItems.length);
    }
  }, []);

  const deleteCartItem = async ({ cartItemId }: CartDeleteProps) => {
    const newItems = cartItems.filter(
      (item: CartItemsProps) => item.id !== cartItemId
    );
    setValueToLocalStorage("cartItems", newItems);
    setCartItems(newItems);
    setCartItemsCount(newItems.length);
    toast.success("تم إزالة المنتج بنجاح");
  };

  const addProductToCart = async (
    product: ProductProps | ProductConfigsProps | undefined,
    itemQuantity: number = 1,
  ) => {

    let newCartItems = [];

    const existingProduct: CartItemsProps = cartItems.find(
      (item: { productId: string }) => item.productId === product?.id
    ) as any;

    if (existingProduct) {
      existingProduct.quantity += itemQuantity;
      newCartItems = cartItems.map((item: CartItemsProps) => {
        if (item.productId === product?.id) {
          return existingProduct;
        }
        return item;
      });
      setValueToLocalStorage("cartItems", newCartItems);
    } else {
      const newCartItem = {
        quantity: itemQuantity || 1,
        productId: product?.id,
        product,
        id: uuidv4(),
      };
      newCartItems = [newCartItem, ...cartItems];
      setValueToLocalStorage("cartItems", newCartItems);
    }

    setCartItems(newCartItems as any);
    setCartItemsCount(newCartItems.length);
    openDrawer(true);
  };

  const clearCartItems = () => {
    setCartItems([]);
    setValueToLocalStorage("cartItems", []);
  };

  const handleItemQuantityChange = (
    itemId: string,
    quantity: number | null
  ) => {
    const newItems = cartItems.map((item: CartItemsProps) => {
      if (item.id === itemId) {
        return { ...item, quantity };
      }
      return item;
    });
    setValueToLocalStorage("cartItems", newItems);
    setCartItems(newItems as any);
  };

  const setDefaultQuantityOnBlur = (itemId: string) => {
    const newItems = cartItems.map((item: CartItemsProps) => {
      if (item.id === itemId) {
        return { ...item, quantity: item.quantity || 1 };
      }
      return item;
    });
    setValueToLocalStorage("cartItems", newItems);
    setCartItems(newItems as any);
  };
  return (
    <CartDrawerContext.Provider
      value={{
        open,
        toggleDrawer,
        openDrawer,
        cartItems,
        cartItemsCount,
        deleteCartItem,
        addProductToCart,
        clearCartItems,
        handleItemQuantityChange,
        setDefaultQuantityOnBlur,
      }}
    >
      {children}
    </CartDrawerContext.Provider>
  );
};
export const useCartDrawer = () => {
  const context = useContext(CartDrawerContext);
  if (context === undefined) {
    throw new Error("useCartDrawer must be used within a CartDrawerProvider");
  }
  return context;
};
