"use client";

import { Company } from "@/type/company";
import { createContext, useContext } from "react";

type CompanyContext = {
  company: Company | null;
  currentLang: string;
};

const initialState: CompanyContext = {
  company: null,
  currentLang: "en",
};

export const CompanyContext = createContext<CompanyContext>(initialState);

export const CompanyProvider = ({
  children,
  company,
  currentLang,
}: {
  children: React.ReactNode;
  company: Company;
  currentLang: string;
}) => {
  return (
    <CompanyContext.Provider value={{ company, currentLang }}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  const context = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};
