import Image from "next/legacy/image";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useTranslate } from "@/locales/config-lang";
import { useGetCategories } from "@/api/category";
import { useEffect, useState } from "react";
import { CategoriesProps } from "@/type/category";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { Url } from "url";
import { Company, CompanySocialLinksProps } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";

export default function AppFooter({
  company,
  lang,
}: {
  company: Company;
  lang: string;
}) {
  const { t } = useTranslation(lang, "translation", {});

  const currentLang = lang;

  const router = useRouter();

  const { enableLogin } = company;

  const isArabic = currentLang === "ar";

  const { categories } = useGetCategories(company?.companyId!);

  const [categoriesData, setCategoriesData] = useState<CategoriesProps[]>([]);

  const [companySocialLinks, setCompanySocialLinks] =
    useState<CompanySocialLinksProps>({});

  useEffect(() => {
    if (!company) return;
    (async () => {
      const { facebookURL, instagramURL, twitterURL, youtubeURL, tiktokURL } =
        company!;
      setCompanySocialLinks({
        facebookURL,
        instagramURL,
        twitterURL,
        youtubeURL,
        tiktokURL,
      });
    })();

    const newCategories = categories.slice(0, 5).map((category) => ({
      ...category,
      name: isArabic ? category.arName : category.enName,
    }));

    setCategoriesData(newCategories);
  }, [categories, isArabic, company]);

  return (
    <Box
      sx={{
        px: { xs: "16px", lg: "60px" },
        pt: { xs: "31px", lg: "40px" },
        pb: "16px",
        background: "rgba(0, 0, 0, 0.8)",
        display: "grid",
        gridTemplateColumns: {
          xs: "repeat(3, 1fr)",
          lg: enableLogin ? "repeat(4, 1fr)" : "repeat(3, 1fr)",
        },
        gridTemplateRows: "auto",
        gridTemplateAreas: {
          xs: `"logo logo social"
        "divider divider divider "
        "subscribe subscribe subscribe"
        "links account categories "
        "divider2 divider2 divider2 "
        "rights rights rights "`,
          lg: enableLogin
            ? `"logo logo logo subscribe"
        "divider divider divider divider"
        "links account categories social"
        "divider2 divider2 divider2 divider2"
        "rights rights rights rights"`
            : `"logo logo  subscribe"
        "divider divider divider"
        "links categories social"
        "divider2 divider2 divider2"
        "rights rights rights"
        `,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: { xs: "8px", lg: "16px" },
          alignItems: "center",
          gridArea: "logo",
          pb: { lg: "50px" },
          alignSelf: "flex-start",
        }}
      >
        <Box
          sx={{
            width: { xs: "18px", lg: "30px" },
            height: { xs: "18px", lg: "30px" },
            position: "relative",
          }}
        >
          <Image
            src={company?.logo || "/images/white-logo.png"}
            alt=""
            sizes="(max-width: 768px) 18px, 30px"
            layout="fill"
          />
        </Box>

        <Typography
          sx={{
            fontSize: { xs: "20px", lg: "32px" },
            fontWeight: "800",
            color: "white",
          }}
        >
          {company?.name}
        </Typography>
      </Box>

      <Box sx={{ gridArea: "subscribe", pb: { xs: "32px", lg: "50px" } }}>
        <Typography
          sx={{
            fontSize: { xs: "16px", lg: "24px" },
            fontWeight: "700",
            color: "white",
            mb: "17px",
            width: { xs: "150px", lg: "280px" },
            textTransform: "capitalize",
          }}
        >
          {t("footer-title")}
        </Typography>
        <Box display={"flex"} alignItems={"center"}>
          <TextField
            placeholder={t("enter-e-mail")}
            sx={{
              width: { xs: "267px", lg: "327px" },
              height: { xs: "34px", lg: "40px" },
              color: "white",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
                borderRight: "none",
                borderRadius: "0px",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "0.5px solid white !important",
                borderRight: "none !important",
              },
              "& .MuiInputBase-root ": {
                height: { xs: "34px", lg: "40px" },
                pl: "16px",
                py: "11px",
              },
              "& .MuiInputBase-input": {
                height: { xs: "34px", lg: "40px" },
                padding: "0px",
                "&::placeholder": {
                  fontSize: { xs: "12px", lg: "16px" },
                  color: "rgba(255, 255, 255, 0.8)",
                  opacity: "1",
                },
              },
            }}
          />

          <Button
            sx={{
              width: { xs: "76px", lg: "100px" },
              height: { xs: "35px", lg: "41px" },
              backgroundColor: "primary.main",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              weight: "500",
              color: "white",
              borderRadius: "0px",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              "&:hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            <Typography
              sx={{ textTransform: "capitalize" }}
              fontFamily={"Roboto"}
            >
              {t("subscribe-button")}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Divider
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          gridArea: "divider",
          mb: { xs: "16px", lg: "32px" },
          mt: { xs: "16px", lg: "24px" },
        }}
      />

      <Box sx={{ gridArea: "links", color: "white" }}>
        <Typography
          sx={{
            pb: { xs: "12px", lg: "32px" },
            fontSize: { xs: "12px", lg: "16px" },
          }}
        >
          {t("links")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "12px", lg: "21px" },
          }}
        >
          <Typography fontSize={{ xs: "10px", lg: "14px" }}>
            {t("help-center")}
          </Typography>
          <Typography fontSize={{ xs: "10px", lg: "14px" }}>
            {t("who-are-we?")}
          </Typography>
          <Typography fontSize={{ xs: "10px", lg: "14px" }}>
            {t("footer-offer")}
          </Typography>
          <Typography fontSize={{ xs: "10px", lg: "14px" }}>
            {t("recently-arrived")}
          </Typography>
          <Typography fontSize={{ xs: "10px", lg: "14px" }}>
            {t("contact-us")}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ gridArea: "categories", color: "white" }}>
        <Typography
          sx={{
            pb: { xs: "12px", lg: "32px" },
            fontSize: { xs: "12px", lg: "16px" },
            fontWeight: "400",
          }}
        >
          {t("categories")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "12px", lg: "21px" },
          }}
        >
          {categoriesData.map((category) => (
            <Typography
              key={category.id}
              style={{ cursor: "pointer" }}
              fontSize={{ xs: "10px", lg: "14px" }}
              onClick={() => {
                router.push(
                  category.slug
                    ? `/products?categorySlug=${category.slug}`
                    : `/products?categoryId=${category.id}`
                );
              }}
            >
              {t(category.name)}
            </Typography>
          ))}
        </Box>
      </Box>

      {enableLogin && (
        <Box sx={{ gridArea: "account", color: "white" }}>
          <Typography
            sx={{
              pb: { xs: "12px", lg: "32px" },
              fontSize: { xs: "12px", lg: "16px" },
            }}
          >
            {t("my-account")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "12px", lg: "21px" },
            }}
          >
            <Typography fontSize={{ xs: "10px", lg: "14px" }}>
              {t("account-details")}
            </Typography>
            <Typography fontSize={{ xs: "10px", lg: "14px" }}>
              {t("my-orders")}
            </Typography>
            <Typography fontSize={{ xs: "10px", lg: "14px" }}>
              {t("my-favorites")}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          gridArea: "social",
          color: "white",
          alignItems: { xs: "flex-end" },
        }}
      >
        <Typography
          sx={{
            pb: { xs: "12px", lg: "32px" },
            fontSize: { xs: "12px", lg: "16px" },
            width: "max-content",
          }}
        >
          {t("social-media")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "12px", lg: "16px" },
          }}
        >
          {companySocialLinks?.facebookURL && (
            <Link
              href={companySocialLinks.facebookURL as unknown as Url}
              target="_blank"
            >
              <Box
                sx={{
                  width: { xs: "24px", lg: "40px" },
                  height: { xs: "24px", lg: "40px" },
                  borderRadius: "50%",
                  background: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "rgba(0, 65, 192, 1)",
                  "&:hover": {
                    bgcolor: "rgba(0, 65, 192, 1)",
                    color: "white",
                  },
                }}
              >
                <FontAwesomeIcon width={"12px"} icon={faFacebookF} />
              </Box>
            </Link>
          )}
          {companySocialLinks?.instagramURL && (
            <Link
              href={companySocialLinks.instagramURL as unknown as Url}
              target="_blank"
            >
              <Box
                sx={{
                  width: { xs: "24px", lg: "40px" },
                  height: { xs: "24px", lg: "40px" },
                  borderRadius: "50%",
                  background: "white",
                  color: "rgba(186, 0, 100, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(186, 0, 100, 1)",
                    color: "white",
                  },
                }}
              >
                <FontAwesomeIcon width={"12px"} icon={faInstagram} />
              </Box>
            </Link>
          )}
          {companySocialLinks?.twitterURL && (
            <Link
              href={companySocialLinks.twitterURL as unknown as Url}
              target="_blank"
            >
              <Box
                sx={{
                  width: { xs: "24px", lg: "40px" },
                  height: { xs: "24px", lg: "40px" },
                  borderRadius: "50%",
                  background: "white",
                  color: "rgba(186, 0, 100, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(186, 0, 100, 1)",
                    color: "white",
                  },
                }}
              >
                <FontAwesomeIcon width={"12px"} icon={faTwitter} />
              </Box>
            </Link>
          )}
          {companySocialLinks?.youtubeURL && (
            <Link
              href={companySocialLinks.youtubeURL as unknown as Url}
              target="_blank"
            >
              <Box
                sx={{
                  width: { xs: "24px", lg: "40px" },
                  height: { xs: "24px", lg: "40px" },
                  borderRadius: "50%",
                  background: "white",
                  color: "rgba(12, 63, 246, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(12, 63, 246, 1)",
                    color: "white",
                  },
                }}
              >
                <FontAwesomeIcon width={"12px"} icon={faYoutube} />
              </Box>
            </Link>
          )}
          {companySocialLinks?.tiktokURL && (
            <Link
              href={companySocialLinks.tiktokURL as unknown as Url}
              target="_blank"
            >
              <Box
                sx={{
                  width: { xs: "24px", lg: "40px" },
                  height: { xs: "24px", lg: "40px" },
                  borderRadius: "50%",
                  background: "white",
                  color: "rgba(12, 63, 246, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(12, 63, 246, 1)",
                    color: "white",
                  },
                }}
              >
                <FontAwesomeIcon width={"12px"} icon={faTiktok} />
              </Box>
            </Link>
          )}
        </Box>
      </Box>

      <Divider
        sx={{
          width: "100%",
          height: "0.5px",
          gridArea: "divider2",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          mb: { xs: "11px", lg: "11px" },
          mt: { xs: "24px", lg: "42px" },
        }}
      />

      <Box
        sx={{
          gridArea: "rights",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "10px", lg: "12px" },
            textDecoration: "underline",
          }}
        >
          {t("rights")}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Typography fontSize={{ xs: "10px", lg: "12px" }}>
            {t("privacy-policy")}
          </Typography>
          <Typography fontSize={{ xs: "10px", lg: "12px" }}>
            {t("terms-conditions")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
