import(/* webpackMode: "eager", webpackExports: ["Box","Container","Typography"] */ "__barrel_optimize__?names=Box,Container,Typography!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "__barrel_optimize__?names=Box!=!/vercel/path0/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/@mui/icons-material/FlagCircle.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/app-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/fashion-app/cart-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/fashion-app/lang-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/fashion-app/mobile-sidebar-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/fashion-app/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartDrawerProvider"] */ "/vercel/path0/src/context/cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CompanyProvider"] */ "/vercel/path0/src/context/company.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarDrawerProvider"] */ "/vercel/path0/src/context/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/sections/cart/cart-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/utils/rtl.tsx");
