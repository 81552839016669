import { login } from "@/api/login";
import { useLocales } from "@/locales/config-lang";
import { User, UserLoggedInProps } from "@/type/user";
import {
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
  setValueToLocalStorage,
} from "@/utils/localStorage";
import { useRouter } from "next/navigation";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type AuthContextType = {
  user: User | null;
  loggedIn: (userData: UserLoggedInProps) => Promise<void>;
  logout: () => void;
  loginErrorMsg: string;
  isAuth: boolean;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const router = useRouter();
  const [user, setUser] = useState<User | null>(null);

  const { currentLang } = useLocales();
  const isArabic = currentLang.value === "ar";

  const [isAuth, setIsAuth] = useState(false);
  
  useEffect(() => {
    const storedUser = getValueFromLocalStorage("user");
    if (storedUser) {
      setUser(storedUser);
      setIsAuth(true);
    }
  }, []);


  const [loginErrorMsg, setLoginErrorMsg] = useState("");

  const loggedIn = async (userData: UserLoggedInProps) => {
    try {
      const res = await login({
        email: userData.email,
        password: userData.password,
      });
      const user = res.data;
      setValueToLocalStorage("user", user);
      setUser(user);
      setIsAuth(true);
      router.push("/");
      setLoginErrorMsg("");
    } catch (e) {
      const errorMsg = isArabic
        ? " لقد فشل تسجيل الدخول من فضلك تأكد من بيانات الدخول"
        : "Login failed, Please check login details";
      setLoginErrorMsg(errorMsg);
      console.error(e);
    }
  };

  const logout = () => {
    removeValueFromLocalStorage("user");
    setUser(null);
    setIsAuth(false);
    router.push("/");
  };

  const value = useMemo(() => ({
    user,
    loggedIn,
    logout,
    loginErrorMsg,
    isAuth,
  }), [user, loginErrorMsg, isAuth]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
