"use client";

import React, { useEffect, useState } from "react";
import { useGetCategories } from "@/api/category";
import { Box, Typography } from "@mui/material";
import { usePathname, useRouter } from "next/navigation";
import { CategoriesProps } from "@/type/category";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";

type MainLink = {
  name: string;
  url: string;
};

type CategoryDataProps = MainLink & Partial<CategoriesProps>;

const mainLinks: MainLink[] = [
  { name: "main", url: "/" },
  { name: "offers", url: "/offers" },
];

export default function HomeCategoriesTabs({
  company,
  lang,
}: {
  company: Company;
  lang: string;
}) {
  const router = useRouter();

  const { t } = useTranslation(lang, "translation", {});

  const pathName = usePathname();

  const isArabic = lang === "ar";

  const { categories } = useGetCategories(company?.companyId!);

  const [categoriesData, setCategoriesData] =
    useState<CategoryDataProps[]>(mainLinks);

  useEffect(() => {
    const newCategories = categories.map((category) => ({
      ...category,
      name: isArabic ? category.arName : category.enName,
      url: "",
    }));

    setCategoriesData([...mainLinks, ...newCategories]);
  }, [categories, isArabic]);

  return (
    <Box
      sx={{
        pt: { xs: "14px", lg: "0px" },
        pb: { xs: "14px", lg: "16px" },
        px: { xs: "0px", lg: "40px" },
        pr: { xs: "24px", lg: "40px" },
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        boxShadow: " 0px 0px 10px -2px rgba(130, 0, 0, 0.04)",
        py: { lg: "15px" },
        "& :last-child": {
          borderRight: "none",
        },
      }}
      className="scroll-container"
    >
      {categoriesData.map((category) => (
        <Box
          key={category.name}
          sx={{
            width: "max-content",
            borderRight: "1.5px solid #0000001A",
            display:
              category.url == "/offers"
                ? company?.settings?.showDiscountsPage
                  ? "block"
                  : "none"
                : "block",
          }}
          onClick={() => {
            router.push(
              category.url
                ? category.url
                : category.slug
                ? `/products?categorySlug=${category.slug}`
                : `/products?categoryId=${category.id}`
            );
          }}
        >
          <Typography
            sx={{
              px: { xs: "24px", lg: "30px" },
              fontSize: { xs: "14px", lg: "16px" },
              borderRight: "1.5px solid rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              width: "max-content",

              fontWeight:
                pathName === `/products?categorySlug=${category.slug}` ||
                pathName === `/products?categoryId=${category.id}` ||
                pathName === category.url
                  ? "700"
                  : "400",
              color:
                pathName === `/products?categorySlug=${category.slug}` ||
                pathName === `/products?categoryId=${category.id}` ||
                pathName === category.url
                  ? "primary.main"
                  : "rgba(0, 0, 0, 0.3)",
            }}
          >
            {t(category.name)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
